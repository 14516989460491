import Vue from 'vue'
import api from '@/lib/api'
import _ from 'lodash'

const VERSION = 1 // to force cache invalidation

const devOptions = {
  useSessionStorage: false,
  useLocalStorage: true
}

const resultsStorage = devOptions.useSessionStorage
  ? sessionStorage
  : (devOptions.useLocalStorage ? localStorage : undefined)

function getStorageKey (id) {
  if (!id) throw new Error('must provide "id"')

  if (VERSION) {
    return `${window.location.hostname}:analytics-bq-data:v${VERSION}:${id}`
  }
  return `${window.location.hostname}:${id}:analytics-bq-data`
}

// returns midnight for _today_
function getMidnight () {
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

function storeWithExpiry (key, value, { storage = resultsStorage } = {}) {
  if (!storage) return

  // expiration is at midnight of tmw
  const date = getMidnight()
  date.setDate(date.getDate() + 1)

  const item = {
    value: value,
    expiry: date.getTime()
  }

  const jsonString = JSON.stringify(item)
  try {
    storage.setItem(key, jsonString)
  } catch (err) {
    console.error(`Cannot write analytics to local storage: ${jsonString.length}`)
  }
}

function getWithExpiry (key, { storage = resultsStorage } = {}) {
  let value
  if (storage) {
    let item // may be undefined
    const itemStr = storage.getItem(key)
    if (itemStr) {
      item = JSON.parse(itemStr)
      // handle expiration
      if (Date.now() > item.expiry) {
        localStorage.removeItem(key)
        item = undefined
      }
    }

    value = _.get(item, 'value')
  }

  return value
}

// initial state
const state = {
  analytics: {
    '60673e2fb36a040012ba4a9b': getWithExpiry(getStorageKey('60673e2fb36a040012ba4a9b')),
    '6254da22c0cb480012d4e8c1': getWithExpiry(getStorageKey('6254da22c0cb480012d4e8c1'))
  },
  loading: false,
  dummy: null
}

const getters = {
  analytics: state => id => state.analytics[id],
  loading: state => state.loading,
  data: state => _.get(state.results, 'data', {})
}

const actions = {
  loadWellspan: async ({ dispatch }) => {
    return await dispatch('loadAnalytics', { org: '60673e2fb36a040012ba4a9b' })
  },
  loadAnalytics: async ({ commit }, { org }) => {
    commit('SET_LOADING', true)
    const response = await api.post('bigquery/routines/analytics', { args: [org] })
      .finally(() => commit('SET_LOADING', false))
    const results = _.get(response, 'data.results', [])
    commit('SET_AND_STORE_ANALYTICS', { org, data: results })

    return results
  }
}

const mutations = {
  SET_AND_STORE_ANALYTICS (state, results) {
    const org = results.org

    // this can fail ... for example, if too much data is stored in local storage
    try {
      storeWithExpiry(getStorageKey(org), results)
      Vue.set(state.analytics, org, results)
    } catch (error) {
      console.error(error)
    }
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_RESULTS (state, results) {
    storeWithExpiry(getStorageKey('wellspan'), results)
    state.results = results
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}
