<template>
    <d-input ref="input" :placeholder="placeholder" />
</template>

<script>

import loadJS from 'load-js'

const BASE_URI = 'https://maps.googleapis.com/maps/api/js'

export default {
  name: 'address-autocomplete',
  props: ['placeholder', 'apiKey'],
  created () {
    const libraries = ['geometry', 'places']
    if (this.apiKey) {
      loadJS(`${BASE_URI}?key=${this.apiKey}&libraries=${libraries.join(',')}`).then(() => {
        const input = this.$refs.input.$el
        const options = {
          componentRestrictions: { country: 'us' },
          fields: ['geometry', 'name', 'address_components', 'formatted_address'],
          strictBounds: false
        }
        this.$autocomplete = new window.google.maps.places.Autocomplete(input, options)
        this.$autocomplete.addListener('place_changed', () => {
          const place = this.$autocomplete.getPlace()
          if (place && place.geometry) {
            this.$emit('autocomplete-select', place)
          }
        })
      })
    }
  },
  destroyed () {
    if (this.$autocomplete) {
      window.google.maps.event.clearInstanceListeners(this.$autocomplete)
    }
  }
}
</script>

<style scoped>
input {
  padding: 8px;
  min-width: 300px;
}
</style>

<style>
.pac-container {
  z-index: 10000;
}
</style>
